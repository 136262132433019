// Import Icons
import BannerImage from '../../../assets/Images/image-skilsmisse.png';
// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'YoutubeModule Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: BannerImage, // accepts null
    bannerContent: null, // accepts null
    bottomContent: null,
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<center><h1>Youtube Test Page</h1></center>'
          + '<p class="manchet_underline">En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob eller under andre særlige ansættelsesforhold.</p>'
          + '<p>En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob eller under andre særlige ansættelsesforhold.</p>',
      },
    },
    {
      componentName: 'BlockAreaFullWidth',
      componentData: {
        backgroundColor: ColorGuide.border,
      },
      componentChildren: [
        {
          componentName: 'YoutubeVideo',
          componentData: {
            embed:
              '<iframe title="Youtube Video" width="560" height="315" src="https://www.youtube.com/embed/U0q0udz4j58" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          },
        },
      ],
    },
    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<h3>Youtube Test Page</h3>'
          + '<p class="manchet_underline">En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>'
          + '<p>En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
      },
    },
    {
      componentName: 'BlockAreaFullWidth',
      componentData: {
        backgroundColor: ColorGuide.border,
      },
      componentChildren: [
        {
          componentName: 'ColumnsDefined',
          componentData: {
            columns: '400px auto',
            minWidth: '1024px',
          },
          componentChildren: [
            {
              componentName: 'YoutubeVideo',
              componentData: {
                embed:
                  '<iframe title="Youtube Video" width="560" height="315" src="https://www.youtube.com/embed/U0q0udz4j58" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
              },
            },
            {
              componentName: 'YoutubeText',
              componentData: {
                header: '',
                text:
                  '<h2>Youtube Test Page<h2><p class="intro">Antallet af klagekunder i Sampension-gælleskabet var sidste år det laveste nogensinde.</p>',
              },
            },
          ],
        },
      ],
    },
    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<h3>Youtube Test Page</h3>'
          + '<p class="manchet_underline">En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>'
          + '<p>En mindre gruppe kunder er ikke dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
      },
    },
  ],
};

export default Data;
